import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 964.000000 822.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,822.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M4570 5922 c0 -7 -11 -17 -24 -23 l-24 -11 26 -24 c21 -18 29 -20 37
-10 6 7 19 16 30 20 l20 7 -20 11 c-11 7 -25 19 -32 27 -9 11 -12 12 -13 3z"/>
<path d="M3185 5529 c-206 -21 -472 -86 -671 -165 -132 -52 -340 -152 -388
-188 -17 -13 -39 -26 -49 -29 -63 -23 -283 -192 -416 -322 -175 -171 -349
-400 -334 -442 3 -10 2 -15 -2 -11 -10 10 -27 -14 -64 -89 -136 -277 -161
-588 -67 -856 128 -365 504 -633 1001 -712 122 -19 363 -31 430 -20 l50 7 -55
14 c-222 56 -471 162 -629 268 -390 260 -544 675 -416 1121 34 119 138 328
219 440 287 396 785 721 1311 854 284 73 610 90 885 46 82 -13 -120 47 -223
66 -130 24 -435 34 -582 18z m-1458 -736 c5 3 9 -2 9 -12 0 -9 -8 -17 -17 -17
-10 0 -15 4 -12 9 3 5 -6 21 -18 35 l-24 27 27 -24 c14 -12 30 -21 35 -18z
m-317 -1116 c0 -2 -15 -16 -32 -33 l-33 -29 29 33 c28 30 36 37 36 29z m-81
-87 c0 -3 -17 -18 -37 -35 l-37 -29 29 32 c26 27 27 33 15 48 -13 16 -12 16 9
3 12 -8 22 -16 21 -19z"/>
<path d="M6317 4350 c-103 -26 -179 -97 -197 -185 -13 -61 -7 -94 26 -152 29
-50 59 -73 183 -138 103 -54 129 -82 117 -128 -19 -76 -154 -75 -272 3 l-41
27 -42 -70 c-23 -38 -40 -76 -37 -83 9 -22 125 -75 200 -91 163 -34 326 27
370 138 22 55 23 146 3 185 -27 51 -104 114 -193 155 -118 54 -144 74 -144
109 0 17 9 38 23 50 19 19 31 21 87 18 39 -3 82 -13 108 -25 l43 -21 35 70
c41 82 41 82 -61 116 -73 26 -157 34 -208 22z"/>
<path d="M2420 3935 l0 -405 90 0 90 0 0 165 0 165 135 0 135 0 0 -165 0 -166
88 3 87 3 0 400 0 400 -87 3 -88 3 0 -161 0 -160 -135 0 -135 0 0 160 0 160
-90 0 -90 0 0 -405z"/>
<path d="M3401 4318 c-5 -13 -77 -191 -160 -397 -83 -206 -151 -378 -151 -383
0 -4 42 -8 93 -8 l93 0 29 80 29 80 157 0 157 0 30 -77 29 -78 97 -3 97 -3
-14 33 c-7 18 -80 201 -163 405 l-149 373 -83 0 c-76 0 -83 -2 -91 -22z m178
-455 c1 -10 -23 -13 -89 -13 -49 0 -90 2 -90 4 0 6 81 220 87 230 6 9 91 -196
92 -221z"/>
<path d="M3940 3935 l0 -405 225 0 225 0 0 80 0 79 -135 3 -136 3 -2 320 -2
320 -87 3 -88 3 0 -406z"/>
<path d="M4721 4318 c-5 -13 -77 -191 -160 -397 -83 -206 -151 -378 -151 -383
0 -4 42 -8 93 -8 l93 0 29 80 29 80 157 0 157 0 30 -77 29 -78 97 -3 97 -3
-14 33 c-7 18 -81 201 -163 405 l-149 373 -83 0 c-76 0 -83 -2 -91 -22z m178
-455 c1 -10 -23 -13 -89 -13 -49 0 -90 2 -90 4 0 6 81 220 87 230 6 9 91 -196
92 -221z"/>
<path d="M5260 3935 l0 -405 225 0 225 0 0 80 0 80 -135 0 -135 0 0 325 0 325
-90 0 -90 0 0 -405z"/>
<path d="M6730 4047 c0 -343 5 -373 74 -442 58 -58 124 -79 241 -78 143 1 222
41 273 136 l27 52 3 313 3 312 -90 0 -91 0 0 -270 c0 -307 -4 -330 -64 -355
-69 -29 -150 -12 -178 38 -9 16 -14 101 -18 302 l-5 280 -87 3 -88 3 0 -294z"/>
<path d="M7470 3935 l0 -407 193 4 c154 3 200 8 232 22 50 21 103 71 121 113
18 41 18 134 0 176 -17 41 -76 93 -115 102 -17 4 -31 9 -31 11 0 3 13 16 29
30 68 57 81 198 26 271 -50 64 -96 77 -287 81 l-168 4 0 -407z m298 230 c19
-23 23 -36 18 -64 -8 -49 -35 -73 -93 -78 l-48 -5 0 92 0 92 49 -5 c39 -3 55
-11 74 -32z m46 -334 c20 -19 26 -34 26 -67 0 -25 -6 -49 -15 -58 -23 -22 -83
-38 -133 -34 l-44 3 -1 93 -2 94 72 -4 c59 -3 76 -8 97 -27z"/>
<path d="M3476 3103 c14 -31 24 -67 21 -80 -2 -13 1 -23 7 -23 6 0 11 18 11
40 1 22 -2 40 -5 40 -4 0 -13 18 -20 40 -7 22 -19 40 -26 40 -9 0 -5 -17 12
-57z"/>
<path d="M3650 3140 c-45 -45 -14 -140 45 -140 37 0 65 33 65 78 0 70 -65 107
-110 62z m80 -9 c5 -11 10 -34 10 -51 0 -17 -5 -40 -10 -51 -12 -21 -50 -25
-68 -7 -16 16 -16 100 0 116 18 18 56 14 68 -7z"/>
<path d="M3840 3102 c0 -66 19 -102 54 -102 40 0 56 29 54 101 l-2 64 -5 -69
c-7 -76 -25 -101 -58 -80 -14 9 -18 25 -18 78 0 46 -4 66 -12 66 -9 0 -13 -18
-13 -58z"/>
<path d="M4040 3080 c0 -59 3 -80 13 -80 9 0 12 11 9 36 -4 33 -2 35 19 32 17
-2 25 -11 27 -28 4 -30 22 -52 22 -26 0 9 -5 27 -11 38 -8 14 -8 23 0 31 15
15 14 50 -1 65 -7 7 -27 12 -45 12 l-33 0 0 -80z m75 51 c9 -28 -5 -51 -31
-51 -21 0 -24 5 -24 35 0 30 3 35 24 35 15 0 27 -7 31 -19z"/>
<path d="M4335 3080 c0 -49 4 -80 10 -80 7 0 9 14 7 34 -4 33 -2 35 29 39 l34
4 -32 1 c-31 2 -33 5 -33 37 0 32 3 35 29 35 16 0 32 -6 34 -12 3 -8 6 -6 6 5
1 14 -7 17 -41 17 l-43 0 0 -80z"/>
<path d="M4531 3136 c-7 -16 -9 -31 -6 -34 3 -3 5 1 5 9 1 13 29 -28 30 -43 0
-5 -13 -8 -30 -8 -16 0 -30 -4 -30 -8 0 -4 15 -6 34 -4 29 3 34 1 40 -22 3
-14 11 -26 16 -26 12 0 14 -9 -19 85 -29 79 -29 79 -40 51z"/>
<path d="M4682 3148 c3 -7 13 -38 22 -68 28 -90 27 -87 36 -65 11 27 12 37 1
31 -4 -3 -16 22 -26 54 -10 33 -22 60 -28 60 -5 0 -7 -6 -5 -12z"/>
<path d="M4927 3153 c28 -5 53 -39 53 -73 0 -34 -25 -68 -53 -73 -21 -4 -21
-4 -1 -6 62 -4 95 100 45 140 -13 10 -33 18 -45 18 -20 -2 -20 -2 1 -6z"/>
<path d="M5080 3090 c0 -73 12 -91 57 -89 12 1 10 3 -7 8 -23 6 -25 12 -28 79
-4 92 -22 94 -22 2z"/>
<path d="M5278 3080 c-2 -59 0 -80 10 -80 7 0 12 14 12 36 0 28 4 35 17 32 10
-2 21 -18 27 -36 5 -17 12 -32 16 -32 9 0 0 59 -11 66 -5 3 -2 12 6 20 34 34
7 74 -49 74 -24 0 -25 -2 -28 -80z m67 35 c0 -23 -5 -31 -22 -33 -20 -3 -23 1
-23 33 0 32 3 36 23 33 17 -2 22 -10 22 -33z"/>
<path d="M5460 3080 c0 -47 4 -80 10 -80 6 0 10 33 10 80 0 47 -4 80 -10 80
-6 0 -10 -33 -10 -80z"/>
<path d="M5578 3153 c20 -4 22 -10 22 -79 0 -43 4 -74 10 -74 6 0 10 32 10 75
0 67 2 75 19 75 11 0 22 -6 24 -12 3 -8 6 -6 6 5 1 14 -8 17 -56 16 -32 -1
-48 -4 -35 -6z"/>
<path d="M5750 3080 l0 -80 45 0 c36 0 45 3 44 18 0 10 -3 12 -6 5 -2 -7 -18
-13 -34 -13 -25 0 -29 4 -29 29 0 25 5 30 28 34 l27 4 -27 2 c-25 1 -28 5 -28
35 0 32 3 35 33 39 28 3 26 4 -10 5 l-43 2 0 -80z"/>
<path d="M6030 3153 c0 -19 45 -143 52 -143 4 0 8 4 8 9 0 5 3 18 6 28 5 14 4
15 -4 4 -8 -11 -14 1 -27 47 -10 34 -22 62 -26 62 -5 0 -9 -3 -9 -7z"/>
<path d="M6120 3145 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M6201 3143 c0 -11 3 -13 6 -5 2 6 13 12 24 12 17 0 19 -8 19 -75 0
-43 4 -75 10 -75 6 0 10 31 10 74 0 74 0 75 28 79 15 2 -1 5 -35 6 -54 1 -63
-1 -62 -16z"/>
<path d="M6390 3102 c0 -66 19 -102 54 -102 13 0 30 7 37 17 13 15 12 15 -9 2
-39 -24 -57 -3 -60 75 -4 81 -22 88 -22 8z"/>
<path d="M6493 3100 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z"/>
<path d="M6712 3148 c-24 -24 -14 -57 23 -75 42 -20 45 -53 5 -64 -17 -5 -19
-7 -7 -8 28 -2 57 18 57 38 0 20 -18 38 -54 56 -29 15 -27 47 4 56 21 6 22 7
5 8 -12 1 -26 -4 -33 -11z"/>
<path d="M6875 3080 c0 -47 4 -80 10 -80 6 0 10 33 10 80 0 47 -4 80 -10 80
-6 0 -10 -33 -10 -80z"/>
<path d="M6998 3153 c20 -4 22 -10 22 -79 0 -43 4 -74 10 -74 6 0 10 32 10 75
0 67 2 75 19 75 11 0 22 -6 24 -12 3 -8 6 -6 6 5 1 14 -8 17 -56 16 -32 -1
-48 -4 -35 -6z"/>
<path d="M7165 3080 l0 -80 45 2 c44 1 44 1 8 5 -34 4 -38 7 -38 33 0 25 4 29
33 33 l32 4 -32 1 c-31 2 -33 5 -33 37 0 32 3 35 29 35 16 0 32 -6 34 -12 3
-8 6 -6 6 5 1 14 -7 17 -41 17 l-43 0 0 -80z"/>
<path d="M4874 3125 c-18 -28 -13 -85 11 -105 11 -9 12 -6 3 24 -8 26 -8 46
-1 71 13 41 9 44 -13 10z"/>
<path d="M5166 3023 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4486 3015 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
